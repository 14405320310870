@import-normalize;

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Bold.ttf');
  font-weight: 700;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Nunito', sans-serif;
  overflow: hidden;
}
body {
  position: relative;
}
body > div#root {
  width: 100%;
  height: 100%;
}

* {
  user-select: none;
  &:focus {
    outline: none;
  }
}

div.modal {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  &.cover {
    font-size: 2rem;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(6px);
    z-index: 1000;
  }
  h1 {
    font-size: 4rem;
    font-weight: bold;
  }
  p {
    font-size: 1rem;
  }
  div.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

div#dashboard {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow: auto;
  span.stat {
    color: #0099ee;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .tagline {
    font-size: 1.2rem;
  }
}

div#ingame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  #board {
    position: relative;
    width: 80vmin;
    height: 80vmin;
    border-radius: 40vmin;
    box-shadow: inset 0 0 10px grey;
    div.player {
      position: absolute;
      width: 10vmin;
      height: 10vmin;
      top: calc(40vmin + var(--cosine) * 45vmin - 5vmin);
      left: calc(40vmin - var(--sine) * 45vmin - 5vmin);
      display: flex;
      justify-content: center;
      align-items: center;
      &.left {
        justify-content: flex-end;
        &::after {
          content: '';
          min-width: 1rem;
        }
      }
      &.right {
        &::before {
          content: '';
          min-width: 1rem;
        }
        justify-content: flex-start;
      }
    }
    div.card {
      position: absolute;
      top: calc(
        40vmin + (var(--cosine, 0) * var(--radius, 0) * 10vmin) - 5vmin
      );
      left: calc(40vmin - (var(--sine, 0) * var(--radius, 0) * 10vmin) - 5vmin);
    }
  }
  #info {
    position: absolute;
    top: 1rem;
    left: 1rem;
    .code {
      font-size: 1.2rem;
      cursor: pointer;
      display: inline-block;
      &:hover > div.button {
        background-color: #00aaff;
        &.red {
          background-color: #ff3333;
        }
        &.green {
          background-color: #60b809;
        }
      }
    }
  }

  #hand {
    position: absolute;
    bottom: 0;
    height: 10vh;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    overflow-x: auto;
    div.card {
      display: inline-block;
      $sine: 0.57494548262;
      width: 5.749455167vh;
      height: 10vh;
      margin: 0 1.5px;
      border: none;
      img {
        left: 0;
        top: 0.909041516vh;
        width: 5.749455167vh;
        height: 8.181916969vh;
        width: 5.749455167vh;
      }
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

div.card {
  $cosine: 0.81819172081;
  $sine: 0.57494548262;
  width: 10vmin;
  height: 10vmin;
  border-radius: 10vmin;
  &.focus {
    box-shadow: inset 0 0 5px #0099ee;
    z-index: 300;
    div.overlay {
      display: block;
    }
  }
  &.hidden {
    visibility: hidden;
  }
  &.dragging {
    z-index: 1000;
    position: absolute;
    top: calc(var(--y) * 1px - 5vmin);
    left: calc(var(--x) * 1px - 5vmin);
    img {
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    }
  }
  div.overlay {
    position: relative;
    display: none;
    img {
      position: absolute;
      top: calc(0.909041516vmin + 1rem);
      left: calc(2.125272416vmin + 1rem);
      z-index: 500;
      box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.5);
    }
    div.flip {
      position: absolute;
      top: -9px;
      left: -9px;
      z-index: 500;
    }
    div.flip.show {
      width: 24px;
      height: 18px;
      border-bottom-right-radius: 100px;
      border-bottom-left-radius: 100px;
      overflow: hidden;
      box-sizing: border-box;
    }
    div.flip.show::after,
    div.flip.show::before {
      content: '';
      display: block;
      border-radius: 100px;
      position: absolute;
      box-sizing: border-box;
    }

    div.flip.show::after {
      top: 2px;
      box-shadow: inset 0 -8px 0 2px, inset 0 0 0 2px;
      width: 24px;
      height: 24px;
    }

    div.flip.show::before {
      width: 8px;
      height: 8px;
      border: 2px solid;
      bottom: 4px;
      left: 8px;
    }
    div.flip.hide {
      width: 24px;
      height: 18px;
      border-bottom-right-radius: 100px;
      border-bottom-left-radius: 100px;
      overflow: hidden;
      box-sizing: border-box;
    }

    div.flip.hide::after,
    div.flip.hide::before {
      content: '';
      display: block;
      border-radius: 100px;
      position: absolute;
      box-sizing: border-box;
    }

    div.flip.hide::after {
      top: 2px;
      box-shadow: inset 0 -8px 0 2px, inset 0 0 0 2px;
      width: 24px;
      height: 24px;
    }

    div.flip.hide::before {
      width: 8px;
      height: 8px;
      border: 2px solid transparent;
      box-shadow: inset 0 0 0 6px, 0 0 0 4px, 6px 0 0 0, -6px 0 0 0;
      bottom: 4px;
      left: 8px;
    }
  }
  img {
    box-sizing: border-box;
    background-color: white;
    position: relative;
    top: 0.909041516vmin;
    left: 2.125272416vmin;
    height: 8.181916969vmin;
    width: 5.749455167vmin;
    border: 1px solid #444;
    border-radius: 3px;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
  }
}

@media only screen and (min-width: 600px) {
  div#dashboard {
    padding: 10%;
    h1 {
      font-size: 3rem;
    }
  }
  div#ingame {
    #info {
      top: 10vmin;
      left: 10vmin;
    }
  }
}

input[type='text'] {
  user-select: auto;
  display: inline-block;
  border: 1px solid gray;
  padding: 0.6rem 1rem;
  border-radius: 1.2rem;
  font-size: 1.2rem;
  &:focus {
    border-color: black;
  }
}

p.faded {
  opacity: 0.5;
}

a {
  text-decoration: none;
  color: #0099ee;
  &:hover {
    color: #00aaff;
  }
}

div.button {
  cursor: pointer;
  padding: 0.6rem 1rem;
  background-color: #0099ee;
  display: inline-block;
  border-radius: 1.2rem;
  color: white;
  font-size: 1.2rem;
  &:hover {
    background-color: #00aaff;
  }
  &.red {
    background-color: #ff0000;
    &:hover {
      background-color: #ff3333;
    }
  }
  &.green {
    background-color: #57ad00;
    &:hover {
      background-color: #60b809;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadein {
  opacity: 0;
  animation: fadein 3s linear 10s forwards;
}

@keyframes ldio-h1rin2cgf {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-h1rin2cgf div {
  box-sizing: border-box !important;
}
.ldio-h1rin2cgf > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #1d3f72 transparent #1d3f72 transparent;
  animation: ldio-h1rin2cgf 1s ease-in infinite;
}
.ldio-h1rin2cgf > div:nth-child(2) {
  border-color: transparent;
}
.ldio-h1rin2cgf > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-h1rin2cgf > div:nth-child(2) div:before,
.ldio-h1rin2cgf > div:nth-child(2) div:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: #1d3f72;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 #1d3f72;
}
.ldio-h1rin2cgf > div:nth-child(2) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 #1d3f72;
}
.loadingio-spinner-dual-ring-pnjxq94gztn {
  width: 81px;
  height: 81px;
  display: inline-block;
  overflow: hidden;
}
.ldio-h1rin2cgf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.81);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-h1rin2cgf div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
